import { css } from '@emotion/core';

export const breadCrumb = css`
  margin-top: 75px;
`;

export const breadcrumbFix = css`
  white-space: nowrap;
  margin-right: 0px;
  height: 45px;
  & a {
    padding: 40px 50% 0;
    & span {
      margin-top: -10px;
    }
  }
`;

export const inline = css`
  display: inline;
`;
