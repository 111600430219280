/* eslint-disable no-undef, react/prop-types, react/no-danger, jsx-a11y/alt-text */
import { graphql, Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import Img from 'gatsby-image';
import _ from 'lodash';
import { SRLWrapper } from 'simple-react-lightbox';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import Carousel from 'nuka-carousel';
import Dropdown from 'react-dropdown';
import { getCategoryPlaceholderImage, getNewsArticleImageDefault } from './news-index-template';
import PageTransition from '../components/PageTransition';
import { shorten } from '../utils/shorten';
import { exBarNav, exBarItem, exBarContent, exBarLink, exBarBar, navBasic, barHoverDark } from '../style/mouseOverUnderline';
import { breadCrumb, breadcrumbFix, inline } from '../style/breadCrumb';
import { Box, BoxVisible, Flex } from '../style/basicStyle';
import colors from '../utils/colors';

const NukaCarousel = ({ imageArray }) => {
  const imageArrayParsed = imageArray.map(imageRoot => imageRoot.node && imageRoot.node.childImageSharp && imageRoot.node.childImageSharp.fluid);
  return (
    <Carousel wrapAround>
      {imageArrayParsed.map((childImageSharp, index) => {
        const key = `${index} + ${childImageSharp.src}`;
        return <SRLWrapper><Img key={key} fluid={childImageSharp} /></SRLWrapper>;
      })}
    </Carousel>
  );
};

class NewsTemplate extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: true });
  }

  render() {
    const { props } = this;
    const { data } = props;
    const { articleMarkdown: post } = data;
    const { group: newsCategoryImages } = data.newsCategoryImages;
    const title = shorten({ maxchars: 25, content: post.frontmatter.title });
    const articleCategory = post.fields && post.fields.articleCategory && post.fields.articleCategory[0];
    const imageArrayOriginal = data.newsArticleImages && data.newsArticleImages.edges && _.cloneDeep(data.newsArticleImages.edges);
    let imageArray = imageArrayOriginal.sort((a, b) => b.node.name - a.node.name); // sort alphabetically
    let bannerImage;
    const bannerImageIndex = imageArray.findIndex(imageObj => {
      return imageObj.node && imageObj.node.name === 'banner'; // get banner image index from image array.
    });
    if (bannerImageIndex > -1) {
      bannerImage = imageArray[bannerImageIndex]; // set bannerImage variable if we found a banner image
      imageArray.splice(bannerImageIndex, 1); // remove banner image from image array
    }
    imageArray = imageArray && imageArray.filter(imageObj => imageObj.node && imageObj.node.name !== 'thumbnail'); // exclude the thumbnail image used in the news index page
    const articleDefaultImage = imageArray && ((bannerImage && bannerImage.node) || (imageArray[0] && imageArray.shift().node));
    const articleCategoryImage = articleCategory && getCategoryPlaceholderImage({ newsCategoryImages, articleCategory });
    const { childImageSharp } = articleDefaultImage || articleCategoryImage;
    if (props.errors) {
      props.errors.forEach(err => console.log('newsPost error rendering: ', err));
      throw new Error(props.errors[0] && props.errors[0].message);
    }
    /* end determine if default page */
    return (
      <PageTransition location={props.location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Project Red July News -{post.frontmatter.title}</title>
        </Helmet>
        <Box width={[1]} p={[1]} bg={colors.primary}>
          <Flex css={breadCrumb} wrap={['nowrap']}>
            <Box m={[0]} width={[1, 1, 1]} p={[1, 2]}>
              <div css={[exBarItem, breadcrumbFix]}>
                <div css={[exBarContent, inline]}>News </div>
                <Link to="/news" css={exBarLink}>
                  <span css={exBarBar} />
                </Link>
              </div>
              &gt;
              <div css={[exBarItem, breadcrumbFix]}>
                <div css={[exBarContent, inline]}>{articleCategory} </div>
                <Link to={`/news/${articleCategory.toLowerCase()}`} css={exBarLink}>
                  <span css={exBarBar} />
                </Link>
              </div>
              &gt; {title}
            </Box>
          </Flex>
          <Box width={[9 / 10, 7 / 10]} m={['1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto']}>
            <Flex wrap={['wrap']}>
              <Box fontSize={[4, 4, 5, 6]}>
                <Box width={[1]} p={[1]}>
                  <Box>
                    <SRLWrapper><Img fluid={childImageSharp.fluid} /></SRLWrapper>
                  </Box>
                  {post.frontmatter.title}
                  <Box fontSize={[2, 2, 3, 3]} p={[1]}>
                    {post.fields && post.fields.articleCategory[0]}
                  </Box>
                  <Box fontSize={[2, 2, 3, 3]} p={[1]}>
                    {post.frontmatter.author && `${post.frontmatter.author} · `} {post.frontmatter.date}
                  </Box>
                </Box>
              </Box>
              <Box textAlign="left" width={[1]} px={[3, 3, 0]} color={colors.secondary}>
                <Box fontSize={[2, 3]}>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box textAlign="center" width={[6 / 10]} m={['1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto']}>
            <Flex wrap={['wrap']} textAlign="center">
              <Box textAlign="center" width={[1]}>
                {(imageArray.length === 1 && <SRLWrapper><Img fluid={imageArray[0].node.childImageSharp.fluid} /></SRLWrapper>) || (imageArray.length > 1 && <NukaCarousel imageArray={imageArray} />)}
              </Box>
            </Flex>
          </Box>
        </Box>
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query NewsPostByPath($slug: String!, $relativeDirectory: String!) {
    newsArticleImages: allFile(
      filter: { sourceInstanceName: { eq: "newsArticles" }, relativeDirectory: { eq: $relativeDirectory }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    pageImages: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "index" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1700, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    newsCategoryImages: allFile(
      filter: { sourceInstanceName: { eq: "newsArticles" }, name: { eq: "placeholder" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      group(field: relativeDirectory) {
        field
        fieldValue
        totalCount
        edges {
          node {
            id
            name
            relativePath
            sourceInstanceName
            relativePath
            relativeDirectory
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    articleMarkdown: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      fields {
        articleCategory
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
      }
    }
  }
`;

export default NewsTemplate;
